<script setup lang="ts">
import LayoutHeader from '~/components/header/LayoutHeader.vue';

defineOptions({
  name: 'AuthLayout',
});

useSeoMeta({
  title: 'Portal',
});

const { branding } = useSettingsStore();
</script>

<template lang="pug">
  .app-main
    LayoutHeader
    .page-container
      .logo-and-slogan-container
        .logo-and-slogan
          .slogan {{ branding.slogan }}
          UiLogo(:width="158")
      .login-content
        slot
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/adaptive";
@import "@frontend/ui-kit/src/runtime/styles/colors";
@import "@frontend/ui-kit/src/runtime/styles/spacing";

.app-main {
  height: var(--full-height);
  padding-top: 48px;
}

.page-container {
  display: flex;
  height: 100%;
}

.logo-and-slogan-container {
  @include column_with_space(9vh);
  background: rgba(246, 245, 250, 0.5);
  min-width: 552px;
  height: 100%;
  align-items: center;
  justify-content: center;

  .img {
    width: 358px !important;
    height: auto !important;
  }
}

.logo-and-slogan {
  @include column_with_space(16px);
  max-width: 280px;
  align-items: center;
}

.slogan {
  color: $ui-color-text-secondary;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.2px;
}

.login-content {
  display: flex;
  justify-content: center;
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
}

@include _1200 {
  .logo-and-slogan-container {
    display: none;
  }
}

@include _500 {
  .login-content {
    padding-bottom: 72px;
  }

  .logo-and-slogan-container {
    padding-bottom: 48px;
  }
}
</style>
